export default imgs => [
  {
    cap: `Latest Cohort of Junction Startups Aim to Grow Calgary's Innovation-Driven Sectors`,
    img: imgs.img1,
    link: `https://financialpost.com/pmn/press-releases-pmn/business-wire-news-releases-pmn/latest-cohort-of-junction-startups-aim-to-grow-calgarys-innovation-driven-sectors`,
    date: "Oct 03, 2019",
  },
  {
    cap: `CHAMPIONS FROM EAST TO WEST.`,
    img: imgs.img2,
    link: `https://www.theaccelerator.tech/startups`,
    date: "Fall 2019",
  },
  {
    cap: `iWareTravel: Travel insurance delivered to where you start the trip.`,
    img: imgs.img3,
    link: `https://www.platformcalgary.com/programs-and-events/junction/current-companies-and-alumni/iwaretravel/`,
    date: "Fall 2019",
  },
  {
    cap: `Calgary Business Podcast - Episode 28`,
    img: imgs.img4,
    link: `https://anchor.fm/allen-wazny/episodes/Episode-28-Lancy-Qiu--CEO-and-Co-founder-of-iWare-Travel-e97bkn`,
    date: "Nov. 26, 2019",
  },
  {
    cap: `THE 15 STARTUPS OF ASCENSION 2020`,
    img: imgs.img5,
    link: `https://fintechcadence.com/blog-posts/the-15-startups-of-ascension-2020/`,
    date: "Feb. 18, 2020",
  },
  {
    cap: `Junction | Founders x Foundations`,
    img: imgs.img6,
    link: `https://www.youtube.com/watch?v=hyN0awxUY08`,
    date: "Dec. 16, 2019",
  },
]
