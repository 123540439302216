import { graphql, useStaticQuery } from "gatsby"

const useInTheNewsImgs = () => {
  const query = useStaticQuery(graphql`
    query {
      data1: file(relativePath: { regex: "/in-the-news-1/" }) {
        img1: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      data2: file(relativePath: { regex: "/in-the-news-2/" }) {
        img2: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      data3: file(relativePath: { regex: "/in-the-news-3/" }) {
        img3: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      data4: file(relativePath: { regex: "/in-the-news-4/" }) {
        img4: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      data5: file(relativePath: { regex: "/in-the-news-5/" }) {
        img5: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      data6: file(relativePath: { regex: "/in-the-news-6/" }) {
        img6: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {
    data1: { img1 },
    data2: { img2 },
    data3: { img3 },
    data4: { img4 },
    data5: { img5 },
    data6: { img6 },
  } = query

  return { img1, img2, img3, img4, img5, img6 }
}

export default useInTheNewsImgs
